.screen-validadorCredencialesContainer{
    /* background-color: blue; */
    display: flex;
}

.screen-personPhotoContainer{
    width: 50%;
    /* background-color: green; */
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Centra los elementos horizontalmente */
}

.screen-personPhotoContainer img{
    width: 40vh;
    border-radius: 5vh;  
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
  
}


.screen-personDataContainer{
    width: 50%;
    text-align: left;
    background-color: transparent;
    margin: 20px;
}

.screen-personDataContainer p{
    font-size: 2rem;
    font-family: 'madaniArabicBold';
    color: var(--navyBlue);
    margin: 0;
}

#thin{
    font-family: 'madaniArabicLight';
}


.screen-iconPersonVerified{
    font-size: 10rem!important;
    color:var(--green)
}

#studentStatus{
    color: var(--green)
}




@media (min-width: 400px) and (max-width: 767px) {
    .screen-validadorCredencialesContainer{
        /* background-color: blue; */
        display: grid;
    }

    .screen-personPhotoContainer{
        width: 100%;
        background-color: transparent;
        margin: 0px;
        text-align:center;
        display: flex;
        align-items: center;


    }

    .screen-personPhotoContainer img{
        width: 15vh;
        border-radius: 5vh;  
        box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
    }


    .screen-personDataContainer{
        width: 100%;
        text-align: center;
        background-color: transparent;
        margin: 0px;
        margin-top: 20px;
    }
    
    .screen-personDataContainer p{
        font-size: 1.3rem;
        font-family: 'madaniArabicBold';
        color: var(--navyBlue);
        margin: 5px;
    }
    
    #thin{
        font-family: 'madaniArabicLight';
    }

    .screen-iconPersonVerified{
        font-size: 8rem!important;
        color:var(--green)
    }
}