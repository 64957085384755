table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: scroll;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 1rem;
  }
  
  th {
    background-color: #f4f4f4;
    text-align: left;
    color:black;

  }