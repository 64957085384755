.component-credencialContainerViews{
    display: flex;
}



.component-credencialContainerFront{
    width:25rem;
    height: 40rem;
    background-image: url('../../assets/images/credencialFront.jpg');
    background-size: cover; /* Ajusta la imagen para que cubra todo el div */
    background-position: center; /* Centra la imagen */
    padding-top: 10rem;
    border-radius: 1rem;
}

.component-credencialContainerDatos{
    width: 100%;
    height: 22rem;
    background-color: transparent;
    /*border: red solid 5px;*/
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.component-credencialPhotoContainer{
    width: 8rem;
    height: 9rem;
    background-color: blue;
    overflow: hidden;
    margin-bottom: 1rem;
}

.component-credencialPhoto{
    width: 100%;
    height: auto;
    object-fit: cover;
}

.component-credencialName{
    font-size: 1.2rem;
    font-family: 'madaniArabicBold';
    color: var(--navyBlue);
    margin:0
}

.component-credencialPosicion{
    font-size: 1.2rem;
    font-family: 'madaniArabicBold';
    color: var(--green);
    margin:0
}

.component-credencialOtherData{
    width: 75%;
    height: auto;
    background-color: transparent;
    text-align: left;
    position: absolute;
    bottom:0;
}

.component-credencialSmallData{
    font-size: 0.8rem;
    font-family: 'madaniArabicBold';
    color: var(--navyBlue);
    margin:5px
}

.component-credencialSmallDataRow{
    display: flex;
    justify-content: space-between;
}










.component-credencialContainerBack{
    width:25rem;
    height: 40rem;
    background-image: url('../../assets/images/credencialBack.jpg');
    background-size: cover; /* Ajusta la imagen para que cubra todo el div */
    background-position: center; /* Centra la imagen */
    padding-top: 14rem;
    border-radius: 1rem;
}

.component-credencialContainerDatosBack{
    width: 100%;
    height: 18rem;
    background-color: transparent;
    /*border: red solid 5px;*/
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.component-credencialQrContainer{
    width: 9rem;
    height: 9rem;
    background-color: red;
    overflow: hidden;
    margin-bottom: 1rem;
}

.component-credencialPhoto{
    width: 100%;
    height: auto;
    object-fit: cover;
}

.component-credencialPhone{
    font-size: 0.9rem;
    font-family: 'madaniArabicBold';
    color: var(--navyBlue);
    margin:0
}

.component-credencialUnidadAcademica{
    font-size: 0.9rem;
    font-family: 'madaniArabicBold';
    color: var(--green);
    margin:0;
    position: absolute;
    bottom:0;
}





