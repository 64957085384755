.custom-checkbox {
    display: inline-block;
    cursor: pointer;
  }
  
  .custom-checkbox input {
    display: none; /* Esconder el checkbox nativo */
  }
  
  .checkmark {
    width: 20px; 
    height: 20px; 
    /* border: 2px solid #007BFF; */
    border-radius: 3px;
    position: relative;
    display: inline-block;
    background-color: white;
  }
  
  .custom-checkbox input:checked + .checkmark {
    background-color: var(--green); /* Color de fondo cuando está chequeado */
  }
  
  .custom-checkbox input:checked + .checkmark::after {
    content: '';
    position: absolute;
    left: 6px;
    top: 2px;
    width: 5px; 
    height: 10px; 
    border: solid white; /* Color de la marca de verificación */
    border-width: 0 2px 2px 0; 
    transform: rotate(45deg);
  }
  