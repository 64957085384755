.component-principalContainer{

}

.component-childrenContainer{

    margin:2rem;
    margin-top: 12rem;
}


@media (min-width: 400px) and (max-width: 767px) {
    .component-childrenContainer{
        height: 40vh;
        margin:0rem;
        margin-top: 12rem;
    }
}