.component-navBarContainer{
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    
    position: fixed;
    top: 0;
    background-color: var(--navyBlue);
    width: 100%;
    height: 9rem;    
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 1rem;
    border-top: 1rem #ff4d63 solid;
  }


  .component-navBarItem {
    width: 20vh;
    height: auto;
    background-color: transparent;
    /* border: white 1px solid; */
    justify-content: center;
    align-items: center;
    display: grid;
  }


  .component-navBarItem img{
    width: 100%;
    height: auto;
  }

  .component-navBarItem p{
    font-size: 1.2rem;
    font-family: 'madaniArabicSemiBold';
    color:white;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .component-navBarItemRowIcons {
    width: 15vh;
    height: auto;
    background-color: transparent;
    /* border: white 1px solid; */
    justify-content: center;
    align-items: center;
    display: flex;
    gap:30px
  }

  
  .homeIcon{
    color:#ff4d63;
  }

  .youtubeIcon{
    color:white
  }