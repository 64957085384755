:root{
  --blue: #308FFF;
  --green:#54c98f;
  --orange:#ffae31;
  --navyBlue:#33179c;
  --red:#ff4d63;
  --gold:#A7823B;
  --grey:#d8d8d8;
}

@font-face {
  font-family: 'madaniArabicBlack';
  src:local("MadaniArabicBlack"), url('./assets/fonts/Madani-Arabic-Black.ttf') ;
}

@font-face {
  font-family: 'madaniArabicBold';
  src:local("MadaniArabicBlack"), url('./assets/fonts/Madani-Arabic-Bold.ttf') ;
}

@font-face {
  font-family: 'madaniArabicExtraBold';
  src:local("MadaniArabicBlack"), url('./assets/fonts/Madani-Arabic-Extra-Bold.ttf') ;
}

@font-face {
  font-family: 'madaniArabicExtraLight';
  src:local("MadaniArabicBlack"), url('./assets/fonts/Madani-Arabic-Extra-Light.ttf') ;
}

@font-face {
  font-family: 'madaniArabicLight';
  src:local("MadaniArabicBlack"), url('./assets/fonts/Madani-Arabic-Light.ttf') ;
}

@font-face {
  font-family: 'madaniArabicMedium';
  src:local("MadaniArabicBlack"), url('./assets/fonts/Madani-Arabic-Medium.ttf') ;
}

@font-face {
  font-family: 'madaniArabicRegular';
  src:local("MadaniArabicBlack"), url('./assets/fonts/Madani-Arabic-Regular.ttf') ;
}

@font-face {
  font-family: 'madaniArabicSemiBold';
  src:local("MadaniArabicBlack"), url('./assets/fonts/Madani-Arabic-Semi-Bold.ttf') ;
}

@font-face {
  font-family: 'madaniArabicVariable';
  src:local("MadaniArabicBlack"), url('./assets/fonts/Madani-Arabic-Variable.ttf') ;
}

@font-face {
  font-family: 'madaniArabicBlack';
  src:local("MadaniArabicBlack"), url('./assets/fonts/Madani-Arabic-Black.ttf') ;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
