.filtrosContainer{
    background-color: var(--navyBlue)!important;
    padding:1rem;
    display: flex;
    gap:20px;
    position: sticky;
    top:0;
    justify-content: flex-start;
    align-items: center;
    /* justify-content: space-evenly; */
}

.formSelect{
    width: 30rem!important;
}

.inputName{
    /* background-color: blue; */
    width: 20rem;
    border-radius: 10px;
    border: none;
    outline: none;

}

.buttonDescarga{
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    border: none;
    outline: none;
}

table{
    text-align: left;
    overflow: auto;
    font-weight: bold;
}

.theadClass{
    background-color: var(--navyBlue);
    color:white;
}

.theadClassButton{
    background-color: var(--green);
    color:white;
    border: 3px solid white;
    cursor: pointer;
} 

.theadClassButton:hover{
    background-color: var(--navyBlue);
    color:white;
    border: 3px solid white;
} 

.theadClassExported{
    background-color: var(--green)!important;
    color:black;
}

.screen-tablePersons{
    width:100%;
    /* height: 95vh; */
    background-color: transparent;
    /* border: 2px, solid black; */
    margin-bottom: 2rem;
    overflow: auto;
    
}

.addDeleteButton{
    width:3rem;
    border-radius: 10px;
    border: none;
}




.tableContainer{
    padding:10px;
}


.tableMobileApp{

}

.tableMobileApp th {
    text-align: center;
}

.tableMobileApp tr{
    text-align: center;
}

.tableCheckboxGeneral{
    width: 20px;
    height: 20px;
    border-radius: 10px;
}

.tableCheckboxGeneral input:checked{
    background-color: green; /* Color de fondo cuando está chequeado */
  }



  .screen-personsToExportCounter{

  }

  .screen-personsToExportCounter h3{
    color:white;
    margin: 0;
  }


  .screen-filtrosContainer{
    background-color: var(--navyBlue)!important;
    padding:1rem;
    display: flex;
    gap:20px;
    position: sticky;
    top:0;
    justify-content: flex-start;
    align-items: center;
    /* justify-content: space-evenly; */
}

.screen-filtrosContainer input{
    font-size: 1.5rem;
}

.screen-tableToExport{
    width:100%;
    height: auto;
    min-height: 20vh;
    max-height: 40vh;
    /* background-color: red; */
    /* border: 2px, solid black; */
    margin-bottom: 2rem;
    overflow: auto;
}