.screen-loginPrincipalContainer{
    /* background-color: var(--navyBlue); */
    background-image: url('https://app.tecmm.mx/resources/images/backgrounds/backgroundSquares.png');
    background-size: cover; /* Ajusta la imagen para que cubra todo el div */
    background-position: center; /* Centra la imagen */

    height: 100dvh;
    display: flex;            /* Habilita Flexbox */
    justify-content: center;   /* Centra horizontalmente */
    align-items: center;  
}


.screen-loginFormContainer{
    width: 100%;
    max-width: 500px;           /* Tamaño máximo de la tarjeta */
    height: auto;
    padding: 20px;
    background-color: #fff;     /* Fondo blanco */
    box-shadow: 0px 4px 18px rgba(255, 255, 255, 0.4); /* Sombra */
    border-radius: 20px;         /* Bordes redondeados */
    text-align: center; 

}

.screen-loginFormContainer img{
    width: 70%;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra los elementos horizontalmente */
}

.form h3{
    font-family: 'madaniArabicBold';
    margin-top: 5vh;
    margin-bottom: 3vh;

}


.screen-loginInputsContainer{
    width: 100%;
    height:15vh;
    background-color: transparent;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
}



.login-button {
    display: block;
    width: auto;
    padding:2rem;
    font-weight: bold;
    background: linear-gradient(45deg, rgb(51, 23, 156) 0%, rgb(48, 143, 255) 100%);
    color: white;
    padding-block: 15px;
    margin: 20px auto;
    border-radius: 20px;
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 20px 10px -15px;
    border: none;
    transition: all 0.2s ease-in-out;
  }

  .login-button p{
    margin: 0;
    font-size: 1.2rem;
  }
  
.login-button:hover {
    transform: scale(1.03);
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 23px 10px -20px;
  }
  
.login-button:active {
    transform: scale(0.95);
    box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 15px 10px -10px;
  }